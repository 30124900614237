import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _bc5ebab0 = () => interopDefault(import('../src/pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _750ae380 = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _75a19f57 = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _fba1580c = () => interopDefault(import('../src/pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _0bea4565 = () => interopDefault(import('../src/pages/receipt.vue' /* webpackChunkName: "pages/receipt" */))
const _24a4a10b = () => interopDefault(import('../src/pages/search.vue' /* webpackChunkName: "pages/search" */))
const _5246fcda = () => interopDefault(import('../src/pages/search-booking/index.vue' /* webpackChunkName: "pages/search-booking/index" */))
const _5f472384 = () => interopDefault(import('../src/pages/select-extras/index.vue' /* webpackChunkName: "pages/select-extras/index" */))
const _8b3330d2 = () => interopDefault(import('../src/pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _926eee3a = () => interopDefault(import('../src/pages/travel-extras.vue' /* webpackChunkName: "pages/travel-extras" */))
const _27475b3d = () => interopDefault(import('../src/pages/unsupported-browser.vue' /* webpackChunkName: "pages/unsupported-browser" */))
const _353d5388 = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _5cde5f05 = () => interopDefault(import('../src/pages/manage-booking/modify.vue' /* webpackChunkName: "pages/manage-booking/modify" */))
const _08638306 = () => interopDefault(import('../src/pages/manage-booking/receipt.vue' /* webpackChunkName: "pages/manage-booking/receipt" */))
const _00c3d689 = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _6cdfc364 = () => interopDefault(import('../src/pages/select-extras/fasttrack/index.vue' /* webpackChunkName: "pages/select-extras/fasttrack/index" */))
const _492c656e = () => interopDefault(import('../src/pages/select-extras/lounges/index.vue' /* webpackChunkName: "pages/select-extras/lounges/index" */))
const _59324738 = () => interopDefault(import('../src/pages/select-extras/product/index.vue' /* webpackChunkName: "pages/select-extras/product/index" */))
const _434daa6e = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _193b7f82 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cookie-policy",
    component: _bc5ebab0,
    name: "cookie-policy"
  }, {
    path: "/maintenance",
    component: _750ae380,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _75a19f57,
    name: "manage-booking"
  }, {
    path: "/privacy-policy",
    component: _fba1580c,
    name: "privacy-policy"
  }, {
    path: "/receipt",
    component: _0bea4565,
    name: "receipt"
  }, {
    path: "/search",
    component: _24a4a10b,
    name: "search"
  }, {
    path: "/search-booking",
    component: _5246fcda,
    name: "search-booking"
  }, {
    path: "/select-extras",
    component: _5f472384,
    name: "select-extras"
  }, {
    path: "/terms-and-conditions",
    component: _8b3330d2,
    name: "terms-and-conditions"
  }, {
    path: "/travel-extras",
    component: _926eee3a,
    name: "travel-extras"
  }, {
    path: "/unsupported-browser",
    component: _27475b3d,
    name: "unsupported-browser"
  }, {
    path: "/manage-booking/cancel",
    component: _353d5388,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/modify",
    component: _5cde5f05,
    name: "manage-booking-modify"
  }, {
    path: "/manage-booking/receipt",
    component: _08638306,
    name: "manage-booking-receipt"
  }, {
    path: "/manage-booking/update-details",
    component: _00c3d689,
    name: "manage-booking-update-details"
  }, {
    path: "/select-extras/fasttrack",
    component: _6cdfc364,
    name: "select-extras-fasttrack"
  }, {
    path: "/select-extras/lounges",
    component: _492c656e,
    name: "select-extras-lounges"
  }, {
    path: "/select-extras/product",
    component: _59324738,
    name: "select-extras-product"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _434daa6e,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _193b7f82,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
